import { RoomRequestType } from 'common/models/messages';
import _ from 'lodash';
export const toastModule = {
    namespaced: true,
    state: {
        notifications: [],
    },
    mutations: {
        ADD_TOAST(state, notification) {
            if (state.notifications.length > 0) {
                const last = state.notifications[state.notifications.length - 1];
                if (notification.event?.request.type === last.event?.request.type) {
                    if (last.event?.request.type === RoomRequestType.PlaybackRequest) {
                        state.notifications.splice(state.notifications.length - 1, 1);
                    }
                    else if (last.event?.request.type === RoomRequestType.SeekRequest && last.event?.user.name === notification.event?.user.name) {
                        let removed = state.notifications.splice(state.notifications.length - 1, 1);
                        notification.event.additional.prevPosition = removed[0].event?.additional.prevPosition;
                    }
                }
            }
            state.notifications.push({
                ...notification,
                id: Symbol(),
            });
        },
        REMOVE_TOAST(state, id) {
            const idx = _.findIndex(state.notifications, { id });
            state.notifications.splice(idx, 1);
        },
        CLEAR_ALL_TOASTS(state) {
            state.notifications = [];
        },
    },
};
