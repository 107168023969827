import Vue from "vue";
import { API } from "@/common-http.js";
import CreateRoomForm from "@/components/CreateRoomForm.vue";
import LogInForm from "@/components/LogInForm.vue";
import RoomUtilsMixin from "@/mixins/RoomUtils.js";
import NavUser from "@/components/navbar/NavUser.vue";
import NavCreateRoom from "@/components/navbar/NavCreateRoom.vue";
import Notifier from "@/components/Notifier.vue";
export default Vue.extend({
    name: "app",
    components: {
        CreateRoomForm,
        LogInForm,
        NavUser,
        NavCreateRoom,
        Notifier,
    },
    mixins: [RoomUtilsMixin],
    data() {
        return {
            showCreateRoomForm: false,
            showLogin: false,
            drawer: false,
        };
    },
    methods: {
        logout() {
            API.post("/user/logout").then(res => {
                if (res.data.success) {
                    this.$store.commit("LOGOUT");
                }
            });
        },
    },
    async created() {
        document.addEventListener('fullscreenchange', () => {
            if (document.fullscreenElement) {
                this.$store.state.fullscreen = true;
                document.querySelector("html")?.classList.add("scrollbarBeGone");
            }
            else {
                this.$store.state.fullscreen = false;
                document.querySelector("html")?.classList.remove("scrollbarBeGone");
            }
        });
        await this.$store.dispatch("getNewToken");
        // ask the server if we are logged in or not, and update the client to reflect that status.
        await API.get("/user").then(res => {
            if (res.data.loggedIn) {
                let user = res.data;
                delete user.loggedIn;
                this.$store.commit("LOGIN", user);
            }
        });
    },
});
