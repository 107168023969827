export var Visibility;
(function (Visibility) {
    Visibility["Public"] = "public";
    Visibility["Unlisted"] = "unlisted";
    Visibility["Private"] = "private";
})(Visibility || (Visibility = {}));
export var QueueMode;
(function (QueueMode) {
    QueueMode["Manual"] = "manual";
    QueueMode["Vote"] = "vote";
    QueueMode["Loop"] = "loop";
    QueueMode["Dj"] = "dj";
})(QueueMode || (QueueMode = {}));
export var OttWebsocketError;
(function (OttWebsocketError) {
    OttWebsocketError[OttWebsocketError["UNKNOWN"] = 4000] = "UNKNOWN";
    OttWebsocketError[OttWebsocketError["INVALID_CONNECTION_URL"] = 4001] = "INVALID_CONNECTION_URL";
    OttWebsocketError[OttWebsocketError["ROOM_NOT_FOUND"] = 4002] = "ROOM_NOT_FOUND";
    OttWebsocketError[OttWebsocketError["ROOM_UNLOADED"] = 4003] = "ROOM_UNLOADED";
})(OttWebsocketError || (OttWebsocketError = {}));
export var PlayerStatus;
(function (PlayerStatus) {
    PlayerStatus["none"] = "none";
    PlayerStatus["ready"] = "ready";
    PlayerStatus["buffering"] = "buffering";
    PlayerStatus["error"] = "error";
})(PlayerStatus || (PlayerStatus = {}));
export var Role;
(function (Role) {
    Role[Role["Administrator"] = 4] = "Administrator";
    Role[Role["Moderator"] = 3] = "Moderator";
    Role[Role["TrustedUser"] = 2] = "TrustedUser";
    Role[Role["RegisteredUser"] = 1] = "RegisteredUser";
    Role[Role["UnregisteredUser"] = 0] = "UnregisteredUser";
    Role[Role["Owner"] = -1] = "Owner";
})(Role || (Role = {}));
